/* App.css */
body, html {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f0f2f5;
  overflow: hidden; /* Evita el scroll */

}

.App-header {
  text-align: center;
  padding: 40px 20px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px; /* Espacio entre elementos */
}
h1 {
  color: #004caf;
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  text-align: center;
  background-color: #353535;
  padding: 10px;
  color: white;
  font-size: 1rem;
}
.logo-container {
  width: 50%;  /* Ajusta el tamaño del contenedor */
  max-width: 600px;
  height: auto;
}

img {
  width: 100%;  /* Escala proporcionalmente dentro del contenedor */
  height: auto;
}

@media (max-width: 768px) {
  .logo-container {
    width: 70%; /* Aumentar el tamaño del logo en móviles */
  }

  h1 {
    font-size: 1.8rem; /* Reducir tamaño del título */
    margin-bottom: 15px;
  }

  p {
    font-size: 1rem;
    margin-bottom: 20px;
  }

 

  footer {
    font-size: 0.9rem;
  }
}
