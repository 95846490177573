.not-found {
    text-align: center;
    margin-top: 20vh;
  }
  
  .not-found h1 {
    font-size: 100px;
    color: #0070c0;
  }
  
  .not-found p {
    font-size: 20px;
  }
  
  .not-found a {
    color: #000;
    text-decoration: none;
    font-weight: bold;
  }
  