@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.1);
        opacity: 0.8;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.st0 {
    fill: #004caf;
    transform-origin: center;
    animation: pulse 2s ease-in-out infinite;
}
